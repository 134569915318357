import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import Receipts from './pages/Receipts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:db" element={<Receipts />} />      
    </Routes>
  </BrowserRouter>
);