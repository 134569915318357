import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { getRtData } from '../services/RtData';
import { Box, Typography, Stack, AppBar, Toolbar, Button, IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import './Receipts.css';
import TaacLogo from '../../src/assets/taac-logo-light.png';
import TaacLogoDark from '../../src/assets/taac-logo-dark.png';

function Receipts() {
  const { db } = useParams();
  const method = "last_entries";
  const receiptType = "digitale";

  const [loading, setLoading] = useState(true);
  const [receipts, setReceipts] = useState([]);
  const [selectedReceipt, setSelectedReceipt] = useState(null);

  const rtDataCallback = useCallback(async () => {
    const resultRtData = await getRtData(db, method, receiptType);
    setReceipts(resultRtData);
    setLoading(false);
  }, [db]);

  useEffect(() => {
    rtDataCallback();
  }, [rtDataCallback]);

  function formatDate(dateTimeString) {
    const day = dateTimeString.slice(6, 8);
    const month = dateTimeString.slice(4, 6);
    const hours = dateTimeString.slice(9, 11);
    const minutes = dateTimeString.slice(11, 13);

    const months = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];

    return {
      time: `${hours}:${minutes}`,
      date: `${parseInt(day)} ${months[parseInt(month) - 1]}`,
    };
  }

  function formatAmount(amount) {
    return `€ ${parseFloat(amount.replace(",", ".")).toFixed(2)}`;
  }

  const isValidReceiptUrl = (url) => url.includes("http");

  return (
    <Box sx={{ paddingTop: '110px' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress sx={{color: "#ee492f"}} />
        </Box>
      ) : (
        <Box>
          <AppBar position="fixed" sx={{ top: 0, zIndex: 1000, background: "#ee492f" }}>
            <Toolbar sx={{ justifyContent: 'center' }}>
              <img src={TaacLogoDark} alt="Taac Logo" style={{ height: '40px', padding: '20px' }} />
            </Toolbar>
          </AppBar>

          <Box className="appWrapper">
            <Typography className={receipts.length > 0 ? "sub-title" : "sub-title center"} sx={{ marginBottom: "20px" }}>{receipts.length > 0 ? "Seleziona il tuo scontrino dalla lista" : "Nessuno scontrino da visualizzare"}</Typography>

            {receipts.map((receipt) => {
              const { time, date } = formatDate(receipt.receiptISODateTime);
              const canOpenReceipt = isValidReceiptUrl(receipt.digitalReceiptUrl);
              return (
                <Box
                  key={receipt.order_id}
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    marginBottom: '16px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    cursor: canOpenReceipt ? 'pointer' : 'not-allowed'
                  }}
                  onClick={() => canOpenReceipt && setSelectedReceipt(receipt)}
                >
                  <Stack>
                    <Box sx={{ background: "#f5f5f5", padding: "16px", borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}>
                      <Stack direction="row" className='alignItemsCenter space-between'>
                        <Typography className='content bold'>#{receipt.order_id}</Typography>
                        <Typography className='content italic'>{receipt.zRepNumber} - {receipt.fiscalReceiptNumber}</Typography>
                      </Stack>

                      <Stack direction="row" className='alignItemsCenter space-between'>
                        <Typography className='content bold'>{time}</Typography>
                        <Typography className='content'>{date}</Typography>
                      </Stack>
                    </Box>
                    <Box sx={{ padding: "16px"}}>
                      <Stack direction="row" className='alignItemsCenter space-between'>
                        <Typography className='sub-title'>{formatAmount(receipt.fiscalReceiptAmount)}</Typography>
                        <Button
                          variant='contained'
                          onClick={() => canOpenReceipt && setSelectedReceipt(receipt)}
                          sx={{ color: "white", background: canOpenReceipt ? "#ee492f" : "#ccc" }}
                          disabled={!canOpenReceipt}
                        >
                          {canOpenReceipt ? "Apri scontrino" : "Non disponibile"}
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>
              );
            })}
          </Box>

          {selectedReceipt && (
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1200
              }}
              onClick={() => setSelectedReceipt(null)}
            >
              <Box
                sx={{
                  width: '90%',
                  maxWidth: '600px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '16px',
                  position: 'relative'
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton
                  onClick={() => setSelectedReceipt(null)}
                  sx={{ position: 'absolute', top: '8px', right: '8px' }}
                >
                  <CloseIcon />
                </IconButton>

                <Typography variant="h6" gutterBottom>Ordine #{selectedReceipt.order_id}</Typography>

                <iframe
                  src={selectedReceipt.digitalReceiptUrl}
                  width="100%"
                  height="400px"
                  title="Digital Receipt PDF"
                  style={{ border: 'none', marginTop: '16px' }}
                ></iframe>

                <Button
                  variant="contained"
                  sx={{ color: "white", background: "#ee492f", marginTop: '16px' }}
                  fullWidth
                  onClick={() => window.open(selectedReceipt.digitalReceiptUrl)}
                >
                  Scarica scontrino PDF
                </Button>
              </Box>
            </Box>
          )}

          {receipts.length > 0 && (
            <a href="https://gotaac.it">
              <Stack direction='row' className='footerContainer'>
                <Typography className='footerText'>
                  powered by
                </Typography>
                <img src={TaacLogo} alt='Taac Logo' className='footerLogo' />
              </Stack>
            </a>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Receipts;
