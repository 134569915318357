import axios from 'axios';

export const getRtData = async (ws_db, method, receiptType) => {
	try {
		// Send request
		const response = await axios.post(
			`https://${ws_db}.taac-app.com/web_services/rt_data`,
			{
				params: {
					db: ws_db,
					method: method,
					receiptType: receiptType
				},
			}
		);
		console.log("api_rt_data", response.data.result)

		// Handle response
		if (response.data.result.success) return response.data.result.receipts;
	} catch (error) {
		console.log('Error:', error);
		return null;
	}
};